<div class="main-content">
  <div class="contact">
    <label class="radio">
      <input class="left" value="join" type="radio" (click)="joinAsTesterToggle()" [checked]="joinAsTester" />
      <div class="radio-replacement left">
        <div class="label">Join Beta Tester</div>
      </div>
    </label>
    <label class="radio">
      <input class="right" value="not" type="radio" (click)="joinAsTesterToggle()" [checked]="!joinAsTester" />
      <div class="radio-replacement right">
        <div class="label">Not Interested</div>
      </div>
    </label>
  </div>
</div>
<div *ngIf="joinAsTester" class="content">
  <div class="center-spinner" *ngIf="loadingSpinner">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="!loadingSpinner">
    <p>Hey There!</p>
    <p>
      We're always striving to make our app the best it can be, and
      <b>we need your help</b>
      to do it!
    </p>
    <p>
      We're putting together a special group of
      <b>beta testers to try out new features and give us valuable feedback.</b>
      Would you like to be one of the first to get a sneak peek at what's coming next and help shape the future of our app?
    </p>
    <p>
      If so, just hit the
      <b>"Join Beta Testers"</b>
      button below to opt-in as a beta tester. You can always opt-out in the future if you want to.
    </p>
    <p>Your Input means the world to us, and we can't wait to hear what you think!</p>
    <div *ngIf="betaTesterForm">
      <form (ngSubmit)="onSubmit()" [formGroup]="betaTesterForm">
        <mat-form-field class="form-input">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" formControlName="name" />
        </mat-form-field>
        <mat-form-field class="form-input">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email" />
          <mat-error *ngIf="betaTesterForm.get('email').invalid">Please enter valid email</mat-error>
        </mat-form-field>
        <div fxLayoutAlign="center center" *ngIf="!updateUser">
          <button mat-raised-button color="primary">
            <mat-icon svgIcon="send"></mat-icon>
            {{ submitButtonText }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
